import React, { useEffect } from 'react'
import "./createPost.css";
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import { loader } from "../../../../Assets";
const FacilitiesSection = ({ data, inputChange, submitForm, phoneNumber, handlePhoneInputChange, validator, error, loading, message }) => {
    const { t } = useTranslation();


    useEffect(() => {
        const result = document.getElementById('phone__input').getElementsByClassName('special-label');
        result[0].style.display = 'none';
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        submitForm()
    }

    return (
        <div className="createPost__container">
            <form onSubmit={handleSubmit} className="createPost__form">

                <div className="createPost__input">
                    <div className="d-flex flex-wrap w-100 justify-content-center align-items-center gap-2">
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("bore hole")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="water"
                                value="bore hole"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("electricity")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="electricity"
                                value="electricity"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("wardrobe")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="wardrobe"
                                value="wardrobe"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("heater")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="waterHeater"
                                value="heater"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("water")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="camWater"
                                value="water"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("generator")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="generator"
                                value="generator"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("interphone")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="interphone"
                                value="interphone"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("fenced")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="fenced"
                                value="fenced"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("dining")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="dining"
                                value="dining"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="d-flex justify-content-evenly labelWidth align-items-center text-white border">
                            {t("parking")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="parking"
                                value="parking"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth  text-center d-flex justify-content-evenly align-items-center">
                            {t("a.c")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="airConditioner"
                                value="a.c"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("camera")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="surveillanceCamera"
                                value="camera"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("guard")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="securityGuard"
                                value="guard"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("balconies")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="twoBalconies"
                                value="balconies"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                        <label className="text-white labelWidth d-flex justify-content-evenly align-items-center">
                            {t("balcony")}
                            <input
                                autocomplete="on"
                                type="checkbox"
                                name="oneBalcony"
                                value="balcony"
                                onChange={inputChange}
                                className="mx-2"
                            />
                        </label>
                    </div>
                </div>

                <div className="createPost__input" id="phone__input">
                    <PhoneInput
                        type="text"
                        country={"cm"}
                        
                        enableAreaCodes={true}
                         onlyCountries={['cm']}
                        // areaCodes={{us: ['999']}}
                        inputProps={{
                            name: "phoneNumber",
                            country: "cm",
                            required: true,
                            autoFocus: true,
                        }}
                        // containerStyle={{
                        //   Padding: "5px",
                        // }}
                        inputStyle={{
                            width: "100%",
                            height: "42px",
                            borderRadius: "10px",
                            outline: "none",
                            margin: "10px 0",
                            backgroundColor: "#fff",
                            border: "none",

                        }}
                        regions={["north-america", "africa", "europe"]}
                        value={phoneNumber}
                        onChange={handlePhoneInputChange}
                        // onChange={props.inputChange}
                        // value={props.data.phonNumber}
                        isValid={(value, country) => {
                            if (value.match(/12345/)) {
                                return "Invalid value: " + value + ", " + country.name;
                            } else if (value.match(/1234/)) {
                                return false;
                            } else {
                                return true;
                            }
                        }}
                    />
                    <label className="error text-danger">
                        {validator.message(
                            "phoneNumber",
                            phoneNumber,
                            "required|phoneNumber",
                            {
                                messages: {
                                    required: t("required"),
                                },
                            }
                        )}
                    </label>
                </div>
                <div className="imageUpload__error">
                    <span className="text-danger">{error}</span>
                </div>
                {loading && (<img src={loader} alt="" className="loading__image" />)}
                <div className="submit__container">
                    <button type="submit" className={message === "upload success" ? "register__submit2" : "register__submit"}>
                        {message === "upload success" ? "FINISH" : t("create")}
                    </button>
                </div>
                {error && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    </div>
                )}
            </form>
        </div>
    )
}

export default FacilitiesSection
