import React from 'react'
import { Box, IconButton, LinearProgress } from "@mui/material";
import { MdPhotoCamera } from "react-icons/md";
import "./createPost.css";
import { useTranslation } from 'react-i18next';
import { GrFormClose } from 'react-icons/gr';
import Buttons from './Buttons';


const SectionOne = ({ data, inputChange, nextStep, previewImages, progress, handleImageChange, removeImage, validator }) => {
  const { t } = useTranslation();


  return (
    <div className="createPost__container">
      <form className="createPost__form">
        {previewImages?.map(
          (image, index) =>
            image && (
              <Box sx={{ width: "100%", margin: "10px 0px" }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  key={index}
                />
              </Box>
            )
        )}
        <div className="image__uploadContainer createPost__input">
          <h4 className="image__uploadContainer-header">{t("select photos to upload")}</h4>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              autocomplete="on"
              hidden
              // accept="image/*"
              accept=".jpg, .jpeg, .png"
              type="file"
              multiple
              onChange={handleImageChange}
            />
            <MdPhotoCamera size={50} color="" />
          </IconButton>
        </div>
        <div className="preview__container">
          {previewImages?.map(
            (preview, index) =>
              preview && (
                <div className="my-2">
                  <div className="preview__imageContainer">
                    <img
                      key={index}
                      className="previewImage"
                      src={preview}
                      alt=""

                    />
                    <GrFormClose color="#4db5ff"
                      size={20}
                      className="close__prevIcon"
                      onClick={() => removeImage(index)} />
                  </div>
                </div>
              )
          )}
          {
           previewImages.length < 3 && (
            <label className="error text-danger">
           { validator.message(
            "quality",
            data.type,
            "required|type",
            {
              messages: {
                required: t("upload error") ,
              },
            }
          )}  
          </label>
           )
          }
        </div>
        <div className="createPost__input">
          <select
            className="createPost__select"
            labelid="type-select"
            id="typeSelect"
            label={t("select type")}
            name="type"
            onChange={inputChange}
            value={data.type}
          >
          {
            data.type === "" && (
              <option >{t("select type")}</option>
            )
          }
            <option value="modern studio">{t("modern studio")}</option>
            <option value="modern room">{t("modern room")}</option>
            <option value="guest house">{t("guest house")}</option>
            <option value="simple apartment">{t("simple apartment")}</option>
            <option value="flat">{t("flat")}</option>
          </select>
          <label className="error text-danger">
            {validator.message(
              "quality",
              data.type,
              "required|type",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="createPost__input">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("price")}
            name="price"
            value={data.price}
            onChange={inputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message("price", data.price, "required|price", {
              messages: {
                required: t("required"),
              },
            })}
          </label>
        </div>
        <div className="createPost__input">
          {/* <select
            className="register__select"
            labelid="currency-select"
            id="currencySelect"
            label={t("select currency")}
            name="currency"
            onChange={inputChange}
            value={data.currency}
          >
            <option>{t("select currency")}</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="FCFA">FCFA</option>
            <option value="NAIRA">NAIRA</option>
          </select> */}
          <input
            autocomplete="on"
            type="text"
            placeholder={t("currency")}
            name="currency"
            defaultValue={data.currency}
            className=""
          />
        </div>
        <div className="createPost__input mb-1">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("no available")}
            name="numberAvailable"
            value={data.numberAvailable}
            onChange={inputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message(
              "numberAvailable",
              data.numberAvailable,
              "required|numberAvailable",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="createPost__input">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("category")}
            name="category"
            defaultValue={data.category}
            className=""
          />

        </div>
        <Buttons next={nextStep} />
      </form>
    </div>
  )
}

export default SectionOne