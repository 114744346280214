import React, { useState} from "react";
import { Link, useNavigate } from "react-router-dom";
//import { messageActions } from '../../../redux/authSlice/messageSlice';
import "./profile.css";
import { MdPhotoCamera } from "react-icons/md";
import { Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { storage, auth } from "../../../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { loader, profile } from "../../../Assets";
import { updateProfile  } from "firebase/auth";
import { useDispatch} from "react-redux";
import { useTranslation} from 'react-i18next';
import { singOutAction } from "../../../redux/actions";
// import { GrFormClose } from 'react-icons/gr';


const Sidebar = ({setActiveMenu,activeMenu}) => {
    const { t } = useTranslation();
    const [currentFile, setCurrentFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { displayName, email, photoURL, role,id } = auth.currentUser;


  
    const onFileChangeHandler = (e) => {
      setCurrentFile(e.target.files[0]);
      if (e.target.files.length !== 0) {
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
      }
    };
  
    /**==================UPLOAD PHOTO */
    const onHandleUpload = (e) => {
      try {
        if (!currentFile) {
          setError(t("upload error"));
        } else {
          setError("");
          setLoading(true);
          const storageRef = ref(storage, `/profilePictures/${currentFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, currentFile);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(percent);
            },
            (err) => {
              const errorMessage = err.message;
              setError(errorMessage);
            },
            async () => {
              await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log(url)
                updateProfile(auth.currentUser, {
                  photoURL: url,
                });
                setProgress(0);
                setPreviewImage(null);
                setCurrentFile(null);
                setLoading(false);
              });
            }
          );
        }
      } catch (error) {
        console.log(error)
      }
    };
    /**=====================UPDATE PHOTO================================= */
    const onHandleUpdate = (e) => {
        const storageRef = ref(storage, `/profilePictures/${currentFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, currentFile);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(percent);
          },
          (err) => {
            const errorMessage = err.message;
            setError(errorMessage);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              //  setNewImageUrl(url)
              updateProfile(auth.currentUser, {
                photoURL: url,
              });
              //setNewImageUrl(url);
              setProgress(0);
              setPreviewImage(null);
              setCurrentFile(null);
            });
          }
        );
    };
    /**===============LOGOUT=================== */
    const logOut = () => {
     dispatch(singOutAction(navigate))
    };
  return (
    <div className={activeMenu ? "sidebarActive" : "sidebar"}>
      {/* <GrFormClose size={30} color="#4db5ff" /> */}
      <span className="closeBtn"  onClick={(prev) => setActiveMenu(!prev)}>X</span>
    <div className="profile__avatar-container">
    <img
        alt="avatar"
        src={photoURL ? photoURL : profile}
      />
    </div>
    <form className="profile__form">
      <div className="profile__formContainer">
      {currentFile ? (
      <Box sx={{ width: "100%", margin: "10px 0px" }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    ) : null}
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={onFileChangeHandler}
          />
          <MdPhotoCamera size={30} color="" />
        </IconButton>
      {
        (previewImage || photoURL) && (
          <div className="profile__ImageContainer">
          {/* <span className="image_label">Select Photo</span> */}
          {photoURL ? (
            <div className="imageUpload__button">
              <Button
                type="button"
                variant="contained"
                sx={{ width: 300 }}
                onClick={onHandleUpdate}
                style={{ width: "200px" }}
              >
              {t("update photo")}
              </Button>
            </div>
          ) : (
            <div className="imageUpload__button">
              <Button
                type="button"
                variant="contained"
                sx={{ width: 300 }}
                onClick={onHandleUpload}
                style={{ width: "200px" }}
              >
                {t("upload photo")}
              </Button>
            </div>
          )}
        </div>
        )
      }
        {previewImage && (
          <div className="my-2">
            <div>
              <img
                style={{
                  height: "80px",
                  width: "80px",
                  objectFit: "contain",
                }}
                className="rounded-circle"
                src={previewImage}
                alt=""
              />
            </div>
          </div>
        )}
      </div>

      <div className="imageUpload__error">
        <span className="text-danger">{error}</span>
      </div>
      {loading && <img src={loader} alt="" className="loading__image" />}
    </form>
    <div className="profile__details">
      <h5 className="profile__text">
        <strong style={{ color: "#4db5ff" }}>{t("username")}: </strong>
        {displayName}
      </h5>
      <h5 className="profile__text">
        {email}
      </h5>
    </div>
    <div className="profile__header">
    {/* (
          <Link to={`/${id}/favorites`} className="createPost__link btn btn-secondary">
          {t("favorites")}
          </Link>
        ) : */}
      {
        role === "agent" &&  (
          <>
          <Link to={`/${id}/create-post`} className="createPost__link btn btn-secondary">
      {t("create property")}
      </Link>
      {/* <Link to="/create-for-sale" className="createPost__link btn btn-secondary">
      {t("create for sale")}
      </Link> */}
      <Link to={`/${id}/my-posts`} className="createPost__link btn btn-secondary">
      {t("my posts")}
      </Link>
      <Link to={`/${id}/dashboard`} className="createPost__link btn btn-secondary">
      {t("dashboard")}
      </Link>
          </>
        )
      }
      <button onClick={logOut} className="btn btn-secondary createPost__link">
      {t("logout")} {displayName}
      </button>
    </div>
    </div>
  )
}

export default Sidebar