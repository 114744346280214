import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import "./postCard.css";
import { IoLocationOutline } from "react-icons/io5";
import {  useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Heart } from "react-feather";
import {
  getUsersWhoSavedToFavoritesAction,
     toggleSaveFavoritesAction
     } from "../../redux/actions";
import { ToastContainer, 
  toast
 } from "react-toastify";
import { dateConverter,
   //getSavedData 
  } from "../../utils/utils";
  //import { profile } from "../../Assets";



const PostCard = (post) => {
  const { item, id } = post
  const { user } = useSelector((state) => state?.user)
  //const { savedBy } = useSelector((state) => state.posts)
  const savedBy  = JSON.parse(localStorage.getItem("saver"))
  const [isSavedToFavorites, setIsSavedToFavorites] = useState(false)
  const dispatch = useDispatch()
  const { role,displayName } = user || {};
  const { t } = useTranslation();
   const currentUserId = user?.id;
  
  const {
    type,
    city,
    neighborhood,
    price,
    currency,
    imageUrls,
    timestamp,
    category,
   // postedBy,
   numberAvailable,
    likes
  } = item?.post;

  //const imageIndex = imageUrls?.length;
  const finalDate = dateConverter(timestamp)
 


useEffect(() => {
if(currentUserId){
  savedBy?.forEach((item) => {
    if(item?.saver?.match(currentUserId) && item?.post?.match(id)){
      setIsSavedToFavorites(true)
    }
  })
}
},[savedBy,currentUserId,id,likes])
// console.log(isSavedToFavorites,currentUserId)
useEffect(() => {
 if(currentUserId){
  dispatch(getUsersWhoSavedToFavoritesAction(id,currentUserId))
  
 }

  // eslint-disable-next-line
},[dispatch,currentUserId,id])

// useEffect(() => {
//   if(savedBy?.indexOf(currentUserId) !== -1){
//     setToggleSaved(true)
   
//   }
// },[savedBy,currentUserId,displayName])




  const toggleSave = async () => {
    if(!displayName)return
    if(!isSavedToFavorites){
      dispatch(toggleSaveFavoritesAction(item?.post, id, currentUserId))
      toast.info(t("saved property"), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
    }

  return (
    <div className="post__container m-2">
        <Link className="post__link" to={`/post/${id}`} state={item}>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="postCard__image">
        <div className="postCard__header">
          <h5 className="city__container-head">
            <IoLocationOutline size={13} color="#4db5ff" />
            <span className="city">{city}</span>
          </h5>
              <h5 className="bedrooms">
               {t("available")}:{" "}{t(numberAvailable)}
          </h5>
        </div>
       <div className="postCard__image-container">
       <img src={imageUrls[0]} alt="post card" loading="lazy" />
       </div>

      </div>
      <div className="type">
        <span className=""></span> {t(type.toLowerCase())}
      </div>
      <div className="postCard__body">
        <div className="card__bodyBox">
          <div className="card__subtitlesContainer">
            <h5 className="post__city">
            {city}
            </h5>
            <h5 className="post__price">
              {neighborhood}
            </h5>
            <h5 className="post__price">
              <strong>
              {price}{" "}
            {currency} {
            t(category.toLowerCase()) === t("for rent") && t(type) !== t("guest house") ? t("per month") : t(category.toLowerCase()) === t("for rent") && t(type) === t("guest house") ? t("per night") : ''
            }
              </strong>
            </h5>
            
            <h5 className="subtitle">Posted {finalDate}</h5>
          </div>
          <div className="avatar__button">
            {/* <h5  className="postedBy">
              { postedBy}
            </h5>{" "} */}
            {
              (role === "client" || !role)&& (
                <div className="post__save">
                    <Heart
                    size={20}
                    color={isSavedToFavorites ? "#EC607F" : "#000"}
                    className="post__like-icon"
                    onClick={toggleSave}
                  />
                  <span>{likes}</span>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        theme="colored"
      />
       </Link>
    </div>
  );
};
export default PostCard;
