import {h1,h1a,h1b, im1, im2, im3,im4, im5,} from "../Assets";


export const  landingImages2 =  [im1,im2,im4,im5]

export const  landingImages =  [h1,h1a,h1b,h1a,h1b,im3]

export const houseData = [
    {
        userId:1,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        likes:0,
        dining: "dining",
        parking: "parking",
        oneBalcony: "balcony",
        twoBalconies: "",
        country: "Cameroon",
        fenced: "fenced",
        category: "for rent",
        bedrooms: 1,
        numberOfToilets: 1,
         securityGuard: "guard",
         surveillanceCamera: "CCTV",
         wardrobe: "wardrobe",
         electricity: "Prepaid",
         water: "forage",
         airConditioner: "a.c",
         camWater:"cam water",
         generator:"generator",
         waterHeater:"heater",
         interphone:"interphone",
        phoneNumber:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
  
    {
        userId:11,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        likes:0,
        dining: "dining",
        parking: "parking",
        oneBalcony: "balcony",
        twoBalconies: "",
        country: "Cameroon",
        fenced: "fenced",
        category: "for rent",
        bedrooms: 1,
        numberOfToilets: 1,
         securityGuard: "guard",
         surveillanceCamera: "CCTV",
         wardrobe: "wardrobe",
         electricity: "Prepaid",
         water: "forage",
         airConditioner: "a.c",
         camWater:"cam water",
         generator:"generator",
         waterHeater:"heater",
         interphone:"interphone",
        phoneNumber:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        userId:111,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        likes:0,
        dining: "dining",
        parking: "parking",
        oneBalcony: "balcony",
        twoBalconies: "",
        country: "Cameroon",
        fenced: "fenced",
        category: "for rent",
        bedrooms: 1,
        numberOfToilets: 1,
         securityGuard: "guard",
         surveillanceCamera: "CCTV",
         wardrobe: "wardrobe",
         electricity: "Prepaid",
         water: "forage",
         airConditioner: "a.c",
         camWater:"cam water",
         generator:"generator",
         waterHeater:"heater",
         interphone:"interphone",
        phoneNumber:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        userId:1111,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        likes:0,
        dining: "dining",
        parking: "parking",
        oneBalcony: "balcony",
        twoBalconies: "",
        country: "Cameroon",
        fenced: "fenced",
        category: "for rent",
        bedrooms: 1,
        numberOfToilets: 1,
         securityGuard: "guard",
         surveillanceCamera: "CCTV",
         wardrobe: "wardrobe",
         electricity: "Prepaid",
         water: "forage",
         airConditioner: "a.c",
         camWater:"cam water",
         generator:"generator",
         waterHeater:"heater",
         interphone:"interphone",
        phoneNumber:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    
    
]
