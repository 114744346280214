import React, { useState,useEffect } from 'react'
import './styles.css'
import { PostsTableCard } from '../../../../components';
import { TiEdit } from "react-icons/ti";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreatePostMainContainer from '../../Dashboard/CreatePost/CreatePostMainContainer';
import Favorites from '../favorites/Favorites';
///import { auth } from '../../../../firebaseConfig';


const MyProperties = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState("All");
  const {posts} = useSelector((state) => state.posts)
  const [myPosts, setMyPosts] = useState([])
  const { user } = useSelector((state) => state.user)
  const {role, id } = user || {};
  //const { photoURL } = auth.currentUser


  useEffect(() => {
    const mine = posts?.filter((post) => post?.post?.userId === id)
    setMyPosts(mine)
  }, [posts,id])

  const handleDelete = (id) => {
    alert(`You want to Delete user ${id}`);
  };
 

  // const handleDelete = async () => {
  //   const postDocRef = doc(db, "apartments", id);
  //   try {
  //     await deleteDoc(postDocRef);
  //   } catch (err) {
  //     alert(err);
  //   }
  // };
  const COLUMNS = [
    {
      label: "Type",
      renderCell: (item) =>(<span className="cell__item">{item?.type}</span>)
    },
    {
      label: t("price"),
      renderCell: (item) => (<span className="cell__item">{item?.price}</span>),
    },
    { label: t("city"), renderCell: (item) => (<span className="cell__item">{item?.city}</span>)},
    // { label: t("no of bedrooms"), renderCell: (item) =>  (<span className="cell__item">{item?.bedrooms}</span>) },
    // { label: t("no of toilets"), renderCell: (item) =>  (<span className="cell__item">{item?.numberOfToilets}</span>) },
    { label: t("no available"), renderCell: (item) =>  (<span className="cell__item">{item?.numberAvailable}</span>) },
    // { label: "Created", renderCell: (item) =>  (<span className="cell__item">{item?.joinedOn}</span>) },
    // { label: "Created", renderCell: (item) =>  (<span className="cell__item">{item?.joinedOn}</span>) },
    {
      label: "Action",
      renderCell: (item) => ""
      (
        <div className="d-flex justify-content-start align-items-center">
          <span className="mail mx-1">
          <Link to={`/${id}/edit-post/${item?.id}`} className="post__more" state={item}>
            <TiEdit 
              size={20}
              color="white"
            />
            </Link>
          </span>
          <span className="delete mx-1">
            <RiDeleteBinLine
              size={20}
              color="white"
              onClick={() => handleDelete(item?.id)}
            />
          </span>
        </div>
      ),
    },
  ];
  
  
  return (
    <div
   
   className="w-100 d-flex flex-column justify-content-center align-items-center"
  >
    {
      role === "client" ? (<Favorites/>) : (
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
    <div className="d-flex justify-content-start align-items-center promotionScreen_header">
      <button className={active === "All"? "promotionActive" : "promotion"} onClick={() => setActive("All")}>{t("all properties")}</button>
      {/* {
        photoURL ? ( */}
          <button className={active === "New"? "promotionActive" : "promotion"} onClick={() => setActive("New")}>{t("create")} {" "}Property</button>
      {/* //   ) :(
      //     <button className={active === "New"? "promotionActive" : "promotion"}>{t("add profile picture")}</button>
      //   )
      // } */}

      </div>
      {
      active === "All" ? (<PostsTableCard columns={COLUMNS} data={myPosts} userId={id} />)
      : ( <CreatePostMainContainer/>)
}
    </div>
      )
    }
</div>
  )
}

export default MyProperties;
