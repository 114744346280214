import React, { lazy, useState, useEffect, Suspense,useContext } from "react";
import { loader } from "../../Assets";
import "./home.css";
import { useTranslation } from 'react-i18next';
//import { useNavigate } from "react-router-dom";
//import Pagination from "./paginate";
import { useSelector } from "react-redux";
import { NoPropertyFallback } from "../../components";
import { finalDateConverter, today } from "../../utils/utils";
import { Link } from "react-router-dom";
import { IPInfoContext } from 'ip-info-react';







const PostCard = lazy(() => delayForDemo(import('../../components/postCard/PostCard')));
const HomeScreen = ({ posts, currentLocation }) => {
  const { city, suburb } = currentLocation || {};
  const { t } = useTranslation();
  const { isSearchLoading,totalRentalProperties } = useSelector((state) => state.posts)
  const [filtered, setFiltered] = useState([])
  const [value, setValue] = useState('')
  const [valueMatches, setValueMatches] = useState(false)
  const { subscriptionExpiryDate } = useSelector((state) => state.posts)
  const { user } = useSelector((state) => state.user)
  const currentUserId = user?.id
  const finalDate = finalDateConverter(subscriptionExpiryDate)
  const locationInfo = useContext(IPInfoContext)

  //console.log(subscriptionExpiryDate)
  //const navigate = useNavigate();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [numPerPage] = useState(10);
  //;
  // const indexOfLastRecord = currentPage * numPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - numPerPage;
  // const currentRecords = filteredApartments.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(filteredApartments.length / numPerPage);



  //const [state, dispatch] = useReducer(first, second, third)



  useEffect(() => {
    setFiltered(posts)
    // console.log(posts)
  }, [posts])


  useEffect(() => {

    const newPosts = posts?.filter(
    
      ({ post }) => post?.type?.match(value.toLowerCase()))
    // console.log(newPosts)
    // // console.log(value,newPosts)

    if (newPosts?.length > 0) {
      setFiltered(newPosts)
      //setValue("")
    } else {
      setValueMatches((prev) => !prev)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  //console.log(filtered)
  // console.log(value)

  const handleChange = (e) => {
    setValue(e.target.value)
    //setFiltered([])
  }

  // if(valueMatches === "no match found"){
  //   return (
  //     <NoPropertyFallback/>
  //   )
  // }

  return (
    <main className="home__screen" id="top">
      <div className="subscription_notice">
        <h4 className="totalRentalProperties">{totalRentalProperties} {t("available properties")}</h4>
        <h4 className="totalRentalProperties">Latitude:{locationInfo.latitude} : Longitude:{locationInfo.longitude}</h4>
            {
              (subscriptionExpiryDate && currentUserId) ? (
                <div className="home__expiry-date">
                  {today > subscriptionExpiryDate ? <p className="home__expired-date">{t("expired subscription")}{" "}{finalDate}</p> : <p className="home__expire-soon-date">{t("active subscription")} {" "}{finalDate}</p>}
                </div>
              ) :
                (
                  <div className="home__expiry-date">
                    <Link to="/subscribe" className="home__expiry-date-link">{t("subscription notice")}</Link>
                  </div>
                )
            }
          </div>
      <div className="home__header">
        <div className="locationAndDateContainer">
          {
            city && (
                <div className="locationContainer">
                  <span>{suburb}</span>
                  <span>{city} </span>
              </div>
            )
          }
          
        </div>
        <div className="home__filter">
          <label htmlFor="">{t("filter")}</label>
          <select
            className="home__filter-select"
            labelid="type-select"
            id="typeSelect"
            label="apartmentType"
            name="type"
            onChange={handleChange}
            value={value}
          >
            <option value="">{t("get all properties")}</option>
            <option value="modern room">{t("modern room")}</option>
            <option value="simple apartment">{t("simple apartment")}</option>
            <option value="modern studio">{t("modern studio")}</option>
            <option value="flat">{t("flat")}</option>
            <option value="guest house">{t("guest house")}</option>
          </select>

        </div>

      </div>
      <div className="home__postsContainer text-secondary">
        {(isSearchLoading) && (
          <Suspense fallback={
            <img src={loader} alt="" className="loading__image" />
          } />
        )}
        {/* <Suspense fallback={
          <img src={loader} alt="" className="loading__image" />
        }>        */}
<div className="posts__container-items">
            {filtered?.map((post) => (

<PostCard item={post} id={post?.id} key={post?.id} />
))}
</div>
          
        {/* </Suspense> */}
        {
          valueMatches && (
            <NoPropertyFallback />
          )
        }
      </div>
      {/* <div className="home__postsContainer text-secondary">
        {apartments?.map((item) => (
       
          <Suspense fallback={ 
            <img src={loader} alt="" className="loading__image"/>
         }>
            <PostCard item={item?.post} id={item?.id} key={item?.id} user={displayName} />
        </Suspense>
         
        ))}
      </div> */}
      {/* <div className="my-4">
      <Pagination nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage} />
      </div> */}
    </main>
  );
};

export default HomeScreen;

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 10000);
  }).then(() => promise);
}