import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useValidator from "../../../../utils/CustomValidator";
import { db, storage } from "../../../../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "./createPost.css";
import { useTranslation } from 'react-i18next';
import { IPInfoContext } from 'ip-info-react';
import {
  useSelector,
  //useDispatch
} from "react-redux";
import { getCurrentCoordinatesAndLocation } from "../../../../utils/utils";
import SectionOne from "./SectionOne";
import PropertyLocationSection from "./PropertyLocationSection";
import FacilitiesSection from "./FacilitiesSection";

///import { createPostAction } from "../../../redux copy/actions";
const yaoundeLat = 3.86667
const yaoundeLong = 11.51667
const AddPropertyForRent = () => {
  const userInfo = useContext(IPInfoContext);
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state?.user)
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [validator, showValidationMessage] = useValidator();
  const [progress, setProgress] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [locationData, setLocationData] = useState([])
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(null);
  const [postData, setPostData] = useState({
    type: "",
    city: "",
    neighborhood: "",
    streetNumber: "",
    postalCode: "",
    price: "",
    category: t("for rent"),
    currency: "FCFA",
    bedrooms: "",
    numberOfToilets: "",
    numberAvailable: "",
    dining: "",
    parking: "",
    fenced: "",
    oneBalcony: "",
    twoBalconies: "",
    securityGuard: "",
    surveillanceCamera: "",
    wardrobe: "",
    electricity: "",
    water: "",
    airConditioner: "",
    camWater:"",
generator:"",
waterHeater:"",
interphone:"",
  });
  const {
    type,
    city,
    neighborhood,
    price,
    category,
    currency,
    numberOfToilets,
    numberAvailable,
    bedrooms,
    dining,
    parking,
    fenced,
    oneBalcony,
    twoBalconies,
    securityGuard,
    surveillanceCamera,
    wardrobe,
    electricity,
    water,
    camWater,
generator,
waterHeater,
interphone,
    airConditioner
  } = postData;
  const [countryCode, country, citiesData] = locationData;
console.log(countryCode)
console.log(userInfo)
console.log(yaoundeLat,yaoundeLong)

  useEffect(() => {
    const getData = async () => {
      const data = await getCurrentCoordinatesAndLocation(userInfo.latitude,userInfo.longitude)
      // const data = await getCurrentCoordinatesAndLocation(yaoundeLat, yaoundeLong)
      setLocationData(data)

    }
    getData()
  }, [userInfo.latitude,userInfo.longitude]);
  //[userInfo?.latitude, userInfo?.longitude]
  const previous = () => {
    setStep((prev) => prev - 1);

  };

  const next = () => {
    if(validator.allValid() && previewImages.length !== 0){
      setStep((prev) => prev + 1);
    }else{
      showValidationMessage(true)
    }
  };
  const handlePhoneInputChange = (phone) => {
    setPhoneNumber(phone);
  };
  const removeImage = (index) => {
    const newPreviews = previewImages.filter((_, i) => i !== index);
    setPreviewImages(newPreviews);
  };
  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      setPreviewImages((prev) => [
        ...prev,
        URL.createObjectURL(e.target.files[i]),
      ]);
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };



  const handleAddProperty = async () => {
    setLoading(true);
    const promises = [];
    images.map((image) => {
      if (!image) {
        setError(t("please upload photos"));
      } else {
        setError("");
        const storageRef = ref(storage, `/postImages/${image.name}`);

        const uploadTask = uploadBytesResumable(storageRef, image);
        promises.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(percent);
          },
          (err) => {
            const errorMessage = err.message;
            setError(errorMessage);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setUrls((prevState) => [...prevState, url]);
            });
          });
      }
      return null
    });

    await Promise.all(promises)
      .then(() => {
        setMessage("upload success");
      })

    if (message === "upload success" && validator.allValid()) {
      // dispatch(createPostAction(postData,id,phoneNumber,urls,navigate))
      addDoc(collection(db, "apartments"), {
        timestamp: serverTimestamp(),
        imageUrls: urls,
        postedBy: user?.fullName,
        userId: user?.id,
        type: type,
        city: city,
        phoneNumber: phoneNumber,
        country: country,
        neighborhood: neighborhood,
        price: price,
        fenced: fenced,
        category: category,
        currency: currency,
        bedrooms: bedrooms,
        numberOfToilets: numberOfToilets,
        numberAvailable: numberAvailable,
        dining: dining,
        parking: parking,
        likes: 0,
        oneBalcony: oneBalcony,
        twoBalconies: twoBalconies,
        securityGuard: securityGuard,
        surveillanceCamera: surveillanceCamera,
        wardrobe: wardrobe,
        electricity: electricity,
        water: water,
        airConditioner: airConditioner,
        camWater:camWater,
        generator:generator,
        waterHeater:waterHeater,
        interphone:interphone,
        // videoUrl:videoLink
        // userProfileUrl: user?.photoURL,
      })
      setProgress(0);
      setPreviewImages(null);
      setLoading(false);
      navigate("/");

    } else {
      showValidationMessage(true);
      setLoading(false);
    }


  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPostData({ ...postData, [e.target.name]: value });
  };

  switch (step) {
    case 1:
      return (
        <SectionOne
          nextStep={next}
          data={postData}
          handleImageChange={handleChange}
          validator={validator}
          removeImage={removeImage}
          inputChange={handleInputChange}
          progress={progress}
          previewImages={previewImages}
        />
      )

    case 2:
      return (
        <PropertyLocationSection
          nextStep={next}
          prevStep={previous}
          data={postData}
          citiesData={citiesData}
          validator={validator}
          inputChange={handleInputChange}
        />
      )
    case 3:
      return (
        <FacilitiesSection
          submitForm={handleAddProperty}
          prevStep={previous}
          data={postData}
          phoneNumber={phoneNumber}
          validator={validator}
          inputChange={handleInputChange}
          handlePhoneInputChange={handlePhoneInputChange}
          error={error}
          loading={loading}
          message={message}
        />
      )
    default:
  }

};

export default AddPropertyForRent;
