import React, { useState, useEffect} from "react";
import Sidebar from './Sidebar'
import "./profile.css"
import { AiOutlineMenu } from "react-icons/ai";
import MyPosts from "./MyProperties/MyProperties";


const Profile = () => {
  const [activeMenu, setActiveMenu] = useState(false)
  const [screenSize, setScreenSize] = useState(true)


  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize()
    window.removeEventListener('resize', handleResize)
   }, [])

   useEffect(() => {
    if(screenSize <= 900){
     setActiveMenu(false)
    }else{
     setActiveMenu(true)
    }
   }, [screenSize])




  return (
      <div className="sidebar__wrapper"> 
      {
        !activeMenu && (
          <AiOutlineMenu size={25} color="#4db5ff" onClick={() => setActiveMenu((prev) => !prev)}/>
        )
      }
    <Sidebar setActiveMenu={setActiveMenu} activeMenu={activeMenu}/>
    <div className={ activeMenu ? "dashboard__active" : "dashboard"}>
   <MyPosts/>
     </div>
      </div>

  );
};

export default Profile;







