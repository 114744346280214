import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { navLogo } from "../../Assets/index";
import { useTranslation } from "react-i18next";
import { landingImages2 } from "../../dummyData/data";
import ImageSlider from "../ImageSlider/ImageSlider";
import { useSelector } from "react-redux";









const Navbar = () => {
 const { user } = useSelector((state) => state.user)
 const {  refreshedUser } = useSelector((state) => state.posts)
  const { isSubscribed  } = refreshedUser || {};
const {  displayName } = user || {}
  const { t, i18n } = useTranslation(); 
  const LANGUAGES = [
    { label: t("english"), code: "en" },
    { label: t("french"), code: "fr" },
    // { label: "Arabic", code: "ar" },
    // { label: "Spanish", code: "es" },
    // { label: "Portogues", code: "es" },
  ];
  


  // console.log(isSubscribed)
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  
  //console.log(isSubscribed)
  return (
    <div className="navbar__container">
      <div className="navbar">
        <Link to="/" className="nav__logo_link">
          <div className="navbar__logo-container">
          <img src={navLogo} alt="logo" />
          </div>
          {/* <span className="website__name-navbar">H-SCRAPER</span> */}
        </Link>
        <div className="nav__linksContainer">
          <select
            defaultValue={"en"}
            className="nav__languageSelector"
            onChange={onChangeLang}
          >
            {LANGUAGES.map(({ code, label }) => (
              <option
                className="nav__language"
                key={code}
                value={code}
                style={{
                  fontWeight: i18n.resolvedLanguage === label ? "bold" : "normal",
                }}
              >
                {label}
              </option>
            ))}
          </select>
          {displayName ? (
            <div className="nav__links">
              
              {
                 isSubscribed === true  ? (
                  <Link to={`/profile/${displayName}`} className="nav__link">
                {displayName}
              </Link>
                ) : ( <Link to="/subscribe" className="nav__link-subscribe">
                {" "}
                {t("subscription link")}
              </Link>)
              }
            </div>
          ) : (
            <div className="nav__links">
              <Link to="/for-sale" className="nav__link">
                {" "}
                {t("buy link")}
              </Link>
              <Link to="/" className="nav__link">
                {" "}
                {t("rent link")}
              </Link>
              <Link to="/login" className="nav__link">
                {" "}
                {t("login")}
              </Link>
            </div>
          )}
        </div>
      </div>
     <div className="navbar__slider">
      <ImageSlider images={landingImages2} />
     </div>
    </div>
  );
};

export default Navbar;
